import {
    MyAccountOrderComponent as SourceMyAccountOrderComponent,
} from 'SourceComponent/MyAccountOrder/MyAccountOrder.component';

import './MyAccountOrder.style';
import {ReactElement} from "Type/Common.type";
import Loader from "Component/Loader";
import {convertStringToDate} from "Util/Manipulations/Date";
import Html from "Component/Html";
import Image from "Component/Image";
import { formatPrice } from 'Util/Price';
import MyAccountOrderInformation from "Component/MyAccountOrderInformation";
import MyAccountOrderTotals from "Component/MyAccountOrderTotals";
import MyAccountAddressTable from 'Component/MyAccountAddressTable';
import {getFormattedRegion, removeEmptyStreets} from 'Util/Address';
import Popup from "Component/Popup";
import Slider from '@mui/material/Slider';
import {Handle, Range, SliderTooltip} from "rc-slider";
import "rc-slider/assets/index.css";
import CustomRequestPopup from "../../../packages/mirasvit-helpdesk/src/component/CustomRequestPopup";
import CustomPopUp from './../../component/CustomPopUp/CustomPopUp.component';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Tracking from './../../../packages/magenear-trackorder/src/component/Tracking'

let statusesLog = '';

const handle = (props: { [x: string]: any; value: any; dragging: any; index: any; }) => {
    const { value, dragging, index, ...restProps } = props;
    return (
        <SliderTooltip
            prefixCls="rc-slider-tooltip"
            // overlay={`${value} %`}
            overlay={<div>Order placed<br/>5:54 PM | Tue, 01 Oct</div>}
            visible={true}
            placement="top"
            key={index}
        >
            <Handle value={value} {...restProps} />
        </SliderTooltip>
    );
};

export class MyAccountOrderComponent extends SourceMyAccountOrderComponent {

    componentWillUnmount() {
        // console.clear();
        try {
            //@ts-ignore
            document.getElementById(
                "header-before-breadcrumbs"
            ).innerHTML = "";
        }catch (e) {

        }
    }

    renderStatusInfo() {
        //@ts-ignore
        const { isMobile, order: { order_tracking, order_date, id, status } } = this.props;

        console.clear();
        console.log(this.props);
        console.log(order_tracking);
        console.log(id);

        statusesLog = (order_tracking) ? order_tracking : 'The order has been successfully delivered to the customer.';

        let statusBarProgress = 0;
        if (status === 'Canceled') {
            return this.renderCanceledStatus();
        }

        if (status === 'Processing') {
            statusBarProgress = 1;
        }

        if (status === 'Shipped') {
            statusBarProgress = 2;
            statusesLog = (order_tracking) ? order_tracking : 'Status will be shown here as soon as the shipment starts moving.';
        }

        if (status === 'Complete') {
            statusBarProgress = 3;
        }

        const currentDate = new Date(order_date);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };

        // const marks = {
        //     0: {
        //         style: {
        //             // color: '#A10101',
        //             left: 26
        //         },
        //         label: 'Placed',
        //     },
        //     1: {
        //         // style: {
        //         //     color: '#A10101',
        //         // },
        //         label: 'Processing',
        //     },
        //     2: {
        //         // style: {
        //         //     color: '#A10101',
        //         // },
        //         label: 'Shipped',
        //     },
        //     3: {
        //         style: {
        //             // color: '#A10101',
        //             left: 'calc(100% - 35px)'
        //         },
        //         label: 'Delivered',
        //     },
        // };

        const marks = [
            {
                value: 0,
                label: 'Placed',
            },
            {
                value: 1,
                label: 'Processing',
            },
            {
                value: 2,
                // label: 'Shipped',
                label:  (statusBarProgress === 2) ? <Tooltip title={<Html content={statusesLog} /> } placement="top">
                    <span>Shipped</span>
                </Tooltip> : 'Shipped',
            },
            {
                value: 3,
                label: 'Delivered',
            },
        ];

        function valuetext(value: number) {
            return  <Html content={statusesLog}/>
            return  <Html content='<div>Order placed<br/>5:54 PM | Tue, 01 Oct</div><br/><div>Order packed<br/>6:54 PM | Tue, 01 Oct</div><br/><div>Order Picked for delivery<br/>8:54 PM | Tue, 01 Oct</div><br/><div>Order in transit<br/>9:54 PM | Tue, 01 Oct</div>'/>
            return '<div>Order placed<br/>5:54 PM | Tue, 01 Oct</div>';
            // return `${value}°C`;
        }

        console.log(statusBarProgress);

        return (
            <>
                <div block="MyAccountOrder" elem="StatusWrapper">
                    <div block="MyAccountOrder" elem="StatusInfo">
                        <span className="DeliveryExpected">Delivery expected by { currentDate.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' }) }</span>
                        {(isMobile) ? '' : this.renderRequestCancellation()}
                    </div>
                    <div block="MyAccountOrder" elem="StatusBar">
                        {/*<Slider*/}
                        {/*    aria-label="Custom marks"*/}
                        {/*    defaultValue={20}*/}
                        {/*    getAriaValueText="asasas"*/}
                        {/*    step={10}*/}
                        {/*    valueLabelDisplay="auto"*/}
                        {/*    marks={marks}*/}
                        {/*/>*/}
                        {/*<div className="StatusBarProgress">slider</div>*/}

                        {/*<Box>*/}
                        {/*    <Slider*/}
                        {/*        aria-label="Custom marks"*/}
                        {/*        // getAriaValueText={valuetext}*/}
                        {/*        // valueLabelFormat={valuetext}*/}
                        {/*        step={1}*/}
                        {/*        max={3}*/}
                        {/*        // valueLabelDisplay="auto"*/}
                        {/*        marks={marks}*/}
                        {/*        defaultValue={statusBarProgress}*/}
                        {/*        value={statusBarProgress}*/}
                        {/*    />*/}
                        {/*</Box>*/}
                        <Tracking orderId={id} status={status} statusBarProgress = {statusBarProgress} />

                        {/*<Range*/}
                        {/*    className="StatusBarProgress"*/}
                        {/*    marks={marks}*/}
                        {/*    step={1}*/}
                        {/*    max={3}*/}
                        {/*    allowCross={false}*/}
                        {/*    defaultValue={[0, statusBarProgress]}*/}
                        {/*    value={[0, statusBarProgress]}*/}
                        {/*    //@ts-ignore*/}
                        {/*    handle={handle}*/}

                        {/*    // defaultValue={[this.state.minPrice, maxPrice]}*/}
                        {/*    // value={this.setRangeValues()}*/}
                        {/*    // max={*/}
                        {/*    //     this.state.defaultMaxPrice*/}
                        {/*    //         ? this.state.defaultMaxPrice*/}
                        {/*    //         : maxPrice*/}
                        {/*    // }*/}
                        {/*    // onChange={this.onSliderChange}*/}
                        {/*    // onAfterChange={this.onSliderAfterChange}*/}
                        {/*/>*/}
                        {/*<Slider*/}
                        {/*    className="StatusBarProgress"*/}
                        {/*    marks={marks}*/}
                        {/*    railStyle={{ background: '#dfdfdf', borderRadius: 8, height: 8 }}*/}
                        {/*    trackStyle={{ backgroundColor: '#A10101', height: 8 }}*/}
                        {/*    dotStyle={{ display: 'none' }}*/}
                        {/*    handleStyle={{ display: 'none' }}*/}
                        {/*    step={1}*/}
                        {/*    max={3}*/}
                        {/*    defaultValue={statusBarProgress}*/}
                        {/*    value={statusBarProgress}*/}

                        {/*/>*/}
                    </div>
                </div>
                {(isMobile) ? this.renderRequestCancellation() : ''}
            </>
        );
    }

    renderRequestCancellation() {
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        const {order: {id, increment_id}} = this.props;

        return (
            // <div className="RequestCancellation">renderRequestCancellation</div>
            <CustomRequestPopup onlyAddNewTicket={
                {
                    customPopUpId: id,
                    orderId: id,
                    incrementId: increment_id,
                    buttonTitle: 'Request cancellation',
                    messageTitle: 'Reason for Cancelling the Order',
                    messagePlaceholder: 'Type here',
                    ticketTitle: 'Request Order Cancellation',
                    ticketSubject: 'Request for cancelling order #' + increment_id,
                    ticketNote: 'Requesting a cancellation of any order doesn’t guarantee that the order will be cancelled for sure. For more info, read our <a href="/terms-and-conditions">terms & conditions</a>',
                    attachment_is_allowed: false,
                    total_required: 3,
                    mix: { block: 'NewTicketPopup RequestCancellation' },
                    showCancel: false,
                    submitTitle: 'Submit'
                }
            } />
        )

    }

    renderCanceledStatus() {
        return (
            <div block="MyAccountOrder" elem="StatusWrapper">
                <div block="MyAccountOrder" elem="StatusInfo">
                    <span className="OrderCanceled">Your order has been cancelled</span>
                    {/*<button*/}
                    {/*    className="ContactUs"*/}
                    {/*    // block="CheckoutAddressBook"*/}
                    {/*    // elem="Button UseThis"*/}
                    {/*    // mods={ { isCustomAddressExpanded } }*/}
                    {/*    mix={ { block: 'Button', mods: { isHollow: true } } }*/}
                    {/*    type="button"*/}
                    {/*    // onClick={ onAddressBookClose }*/}
                    {/*    // onClick={ this.useThisAddress }*/}
                    {/*>*/}
                    {/*    { __('Contact Us') }*/}
                    {/*</button>*/}
                </div>
            </div>
        )
    }


    renderProductsInThisOrder() {

        //@ts-ignore
        const { order: { order_items, total: {grand_total}} } = this.props;

        // console.clear();

        if (typeof order_items === 'undefined') {
            return null;
        }

        const orderItems = JSON.parse(order_items)
        const oneItem = (orderItems.length === 1) ? 'one' : '';

        return (
            <>
                <div block="MyAccountOrderTableRowExpandable" elem="Wrapper">
                    <span className="ProductsInThisOrder">Products in this order</span>
                </div>
                <div block="ProductsInThisOrderWrapper" mods={{OneItem:(!!oneItem)}}>
                    {
                        //@ts-ignore
                        orderItems.map((item, index) => {
                            // console.log(index);
                            // console.log(item);
                            // console.log(grand_total);
                            return (
                                <>
                                    <div className="ProductsInThisOrderWrapperItem">
                                        <div className="ProductsInThisOrderWrapperItemImage">
                                            {(item.product_image) ? <Image src={item.product_image} />:''}
                                        </div>
                                        <div className="ProductsInThisOrderWrapperItemContent">
                                            <div className="ProductsInThisOrderWrapperItemContentName">
                                                <div>
                                                {item.name}
                                                </div>
                                                <div>{formatPrice(item.price, grand_total.currency)}</div>
                                            </div>
                                            <span>Quantity: {item.qty_ordered}</span>
                                            {/*<br/>*/}
                                            {(item.product_options.length) ? <Html content={item.product_options} />:''}
                                            {(item.bundle) ?
                                                <CustomPopUp
                                                    additionalClass = 'show-bundled'
                                                    // selectedAddressId = {selectedAddressId}
                                                    // customFunction = { onAddressSelect }
                                                    // customFunction2 = { this.useThisAddress }
                                                    // customFunction2 = { this._useThisAddress.bind(this, isBilling) }
                                                    // source="customer-address-book"
                                                    // source={addressBookFor}
                                                    heading="Included in the bundle"
                                                    buttonTitle="Show bundled products"
                                                    content={
                                                        <div block="BundleItemsWrapper">
                                                            {item.bundle.map(this.bundledOptions.bind(this))}
                                                        </div>
                                                    }
                                                    // ok="Use this address"
                                                />
                                                : ''}
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                    {orderItems.length === 1 ? <>
                        <div className="ProductsInThisOrderWrapperItem placeholder"></div>
                        <div className="ProductsInThisOrderWrapperItem placeholder"></div>
                    </> : ''}
                    {orderItems.length === 2 ? <>
                        <div className="ProductsInThisOrderWrapperItem placeholder"></div>
                    </> : ''}
                </div>
            </>
        )
    }

    bundledOptions(bundle: any) {
        const {
            // @ts-ignore
            label, quantity, price, id, product_image, product_link
        } = bundle;
        return (
            <div block="ProductBundleItem" elem="Info">
                <div block="ProductBundleItem" elem="Image">
                    <Image src={product_image}/>
                </div>
                <div block="ProductBundleItem" elem="Name">
                    {label}
                </div>
                <div block="ProductBundleItem" elem="Qty">
                    Quantity: {quantity}
                </div>
            </div>
        )
    }

    renderBaseInfo(): ReactElement {
        const { order: { order_date } } = this.props;

        const currentDate = new Date(order_date);

        return (
            <div block="MyAccountOrder" elem="CreationDate">
                { this.renderOrderIncrementIdAndStatus() }
                {/*<span>{ convertStringToDate(order_date) }</span>*/}
                <span>Order placed on { currentDate.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' }) }</span>
            </div>
        );
    }

    renderOrderIncrementIdAndStatus() {
        const { order: { increment_id, status }, isMobile } = this.props;

        // if (!isMobile) {
        //     return null;
        // }

        return (
            <div block="MyAccountOrder" elem="OrderId">
                { __('Order # %s', increment_id) }
                {/*<span block="MyAccountOrder" elem="OrderStatus">*/}
                {/*    { status }*/}
                {/*</span>*/}
            </div>
        );
    }

    renderOrderInformation() {

        return (
            <>
                <div
                    block="MyAccountOrderInfo"
                    elem="Divider"
                ></div>
                <div
                    block="MyAccountOrderInfo"
                    elem="Wrapper"
                >
                    {this.renderShippingAddress()}
                    {this.renderShippingMethod()}
                    {this.renderBillingAddress()}
                </div>
                <div
                    block="MyAccountOrderInfo"
                    elem="Divider"
                ></div>
                <div
                    block="MyAccountOrderInfo"
                    elem="Wrapper"
                >
                    {this.renderOrderComment()}
                    {this.renderPaymentMethods()}
                    {this.renderTotals()}
                </div>
            </>
        )
        // const { order, activeTab } = this.props;
        //
        // if (activeTab === ORDER_REFUNDS) {
        //     return null;
        // }
        //
        // return <MyAccountOrderInformation order={ order } />;
    }

    renderTotals() {
        const { order: {total}, activeTab } = this.props;

        return (
            <>
                <div
                    block="MyAccountOrderInfo"
                    elem="Element"
                >
                    <strong>
                        <span>{ __('Summary') }</span>
                    </strong>
                    <MyAccountOrderTotals activeTab={ activeTab } total={ total } />
                </div>
            </>
        )
    }


    renderShippingAddress() {
        const { order: { shipping_address } = {} } = this.props;

        if (!shipping_address) {
            return null;
        }

        return this.renderAddress('Shipping information', shipping_address, 'is-title');

    }

    renderBillingAddress() {
        const { order: { billing_address } = {} } = this.props;

        if (!billing_address) {
            return null;
        }

        return this.renderAddress('Billing information', billing_address, 'is-title');

    }

    renderShippingMethod() {
        const { order: { shipping_method } = {} } = this.props;
        return this.renderAddress('Shipping method', shipping_method, 'is-title');
    }

    renderOrderComment() {
        //@ts-ignore
        const { order: { order_comment } = {} } = this.props;
        const orderComment = (order_comment) ? order_comment : 'No special instructions';
        return this.renderAddress('Order Note', orderComment);
    }

    renderPaymentMethod (paymentMethod: { name: any; purchase_number: any; }, index: any) {
        const { name, purchase_number } = paymentMethod;

        return (
            <>
                <span>{ name }</span>
                { this.renderPurchaseNumber(purchase_number) }
            </>
        )
    }

    renderPurchaseNumber(purchaseNumber: unknown) {
        if (!purchaseNumber) {
            return null;
        }

        return <span>{ __('Purchase Order Number: %s', purchaseNumber) }</span>
    }

    renderPaymentMethods() {
        const { order: { payment_methods = [] } } = this.props;

        return (
            <>
                <div
                    block="MyAccountOrderInfo"
                    elem="Element"
                >
                    <strong>
                        <span>{ __('Payment Method') }</span>
                    </strong>
                    { payment_methods.map(this.renderPaymentMethod.bind(this)) }
                </div>
            </>
        )
    }

    renderAddress(title: any, address: any, isTitle = '') {
        if (typeof address === 'string') {
            return (
                <div
                    block="MyAccountOrderInfo"
                    elem="Element"
                >
                    <strong>
                        <span>{ title }</span>
                    </strong>
                    <span>{address}</span>
                </div>
            )
        }



        //@ts-ignore
        const { countries } = this.props;
        const regionData = getFormattedRegion(address, countries);
        const fullName = address.firstname + ' ' + address.lastname;
        const fullAddress = address.street.join(', ');
        const fullRegion = regionData.region + ', ' + address.city + ', ' + address.postcode + ', ' + regionData.country;
        const fullTelephone = 'Phone number ' + address.telephone;
        return (
            <div
                block="MyAccountOrderInfo"
                elem="Element"
            >
                <strong>
                    <span>{ title }</span>
                </strong>
                <span className={isTitle}>{fullName}</span>
                <span>{fullAddress}</span>
                <span>{fullRegion}</span>
                <span>{fullTelephone}</span>
            </div>
        )
    }

    renderContent(): ReactElement {
        const { order: { items } } = this.props;

        if (!items) {
            return null;
        }
// console.clear();
//         console.log(items);
        return (
            <div block="MyAccountOrder" elem="Wrapper">
                { this.renderBaseInfo() }
                { this.renderStatusInfo() }
                { this.renderProductsInThisOrder() }
                {/*{ this.renderActions() }*/}
                {/*{ this.renderActiveTab() }*/}
                {/*{ this.renderShippingAddress() }*/}
                {/*{ this.renderTotals() }*/}
                { this.renderOrderInformation() }
                {/*{ this.renderOrderComments() }*/}
            </div>
        );
    }
};

export default MyAccountOrderComponent;
